<template>
  
    <v-container>
      <v-row align="center" justify="center">
        <v-col cols="12" lg="6" md="6" sm="10">
          <vg-loading :loading="loading"></vg-loading>
          <v-card v-if="!loading">
            <v-toolbar dark class="primary white--text" flat>
              <v-toolbar-title>สมัครสมาชิก</v-toolbar-title>
              <v-spacer />
            </v-toolbar>
            <v-card-text>
              <v-form ref="form" lazy-validation v-model="valid" >
              <v-container @keyup.enter="onSubmit">
                <v-row>
                  <v-col>
                    <v-text-field v-model="name" :rules="rules.name" label="ชื่อ - นามสกุล"></v-text-field>
                  </v-col>
                </v-row>
                <!-- <v-row>
                  <v-col>
                    <v-text-field v-model="lastname" :rules="rules.lastname" label="นามสกุล"></v-text-field>
                  </v-col>
                </v-row> -->
                <v-row>
                  <v-col>
                    <v-text-field v-model="section" :rules="rules.section" label="ฝ่าย/แผนก"></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-text-field v-model="username" :rules="rules.username" label="ชื่อบัญชี"></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-text-field
                      v-model="password"
                      :rules="rules.password"
                      label="รหัสผ่าน"
                      type="password"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-text-field
                      v-model="confirm_password"
                      :rules="rules.confirm_password.concat(passwordConfirmationRule)"
                      label="ยืนยันรหัสผ่าน"
                      type="password"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col class="text-left">
                    <v-btn color="secondary" @click="$router.push('/login')">ย้อนกลับ</v-btn>
                  </v-col>
                  <v-col class="text-right">
                    <v-btn @click="onSubmit" color="primary" :disabled="!valid">บันทึก</v-btn>
                  </v-col>
                </v-row>
              </v-container>
              </v-form>
            </v-card-text>
            <v-card-actions></v-card-actions>
          </v-card>
        </v-col>
      </v-row>
      <v-snackbar v-model="toast.visible" :timeout="toast.timeout" :color="toast.color" top>
      {{ toast.message }}
      <v-btn color="white" text @click="toast.visible = false">ปิด</v-btn>
    </v-snackbar>
    </v-container>
    
  
</template>

<script>
export default {
  mounted() {
    this.loading = false;
  },
  updated(){
    this.validate();
  },
  data() {
    return {
      valid: false,
      name: null,
      section: null,
      username: null,
      password: null,
      confirm_password: null,
      rules: {
        name: [v => !!v || "โปรดระบุ"],
        section: [v => !!v || "โปรดระบุ"],
        username: [
          v => !!v || "โปรดระบุชื่อผู้ใช้งาน",
          v =>
            (v && v.length >= 6) ||
            "ชื่อผู้ใช้งานต้องมีความยาวอย่างน้อย 6 ตัวอักษร"
        ],
        password: [
          v => !!v || "โปรดระบุรหัสผ่าน",
          v =>
            (v && v.length >= 6) || "รหัสผ่านต้องมีความยาวอย่างน้อย 6 ตัวอักษร"
        ],
        confirm_password: [
          v => !!v || "โปรดระบุรหัสผ่านอีกครั้ง",
          v =>
            (v && v.length >= 6) || "รหัสผ่านต้องมีความยาวอย่างน้อย 6 ตัวอักษร"
        ]
      },
      loading: true,
      toast: {
        visible: false,
        timeout: 2000,
        color: "error",
        message: ""
      }
    };
  },
  methods: {
    validate() {
      this.$refs.form.validate();
    },
    onSubmit() {
      let app = this;
      app.validate();
      if (!app.valid) return;
      app.loading = true;
      //   console.log(app.firstname)
      //   this.$auth.register({
      //     body: {
      //       firstname: app.firstname,
      //       lastname: app.lastname,
      //       section: app.section,
      //       username: app.username,
      //       password: app.password,
      //       confirm_password: app.confirm_password
      //     },
      //     success() {
      //       app.loading = false;
      //     },
      //     error(err) {
      //       app.toast.message = err.response.data.data
      //         ? err.response.data.data
      //         : "ข้อมูลไม่ถูกต้อง";
      //       app.toast.visible = true;
      //       console.log(err.response.data.data);
      //       app.loading = false;
      //     },
      //     redirect: "/auth/login"
      //   });
      axios
        .post("https://api-kpsw-auth-service.cloudary.dev/api/v1/auth/register", {
          name: app.name,
          department: app.section,
          username: app.username,
          password: app.password,
          confirm_password: app.confirm_password
        })
        .then(response => {
          app.loading = false;
          app.$router.push("/login");
          console.log(response.data);
        })
        .catch(err => {
          app.toast.message = err.response.data.data
            ? err.response.data.data
            : "ข้อมูลไม่ถูกต้อง";
          app.toast.visible = true;
          console.log(err.response.data.data);
          app.loading = false;
        });
    }
  },
  computed: {
    passwordConfirmationRule() {
      return () =>
        this.password === this.confirm_password || "รหัสผ่านไม่ตรงกัน";
    }
  }
};
</script>
