<template>
	<v-container>
		<vg-loading :loading="loading"></vg-loading>
		<v-row align="center" justify="center" v-if="!loading">
			<v-col cols="12" sm="12" lg="7" md="7">
				<v-card class="elevation-0">
					<!-- <v-toolbar dark>
						<v-toolbar-title>{{ user.name }}</v-toolbar-title>
						<v-spacer></v-spacer>
						<v-toolbar-title><v-btn class="error" @click="dialog = true">ลบบัญชีผู้ใช้</v-btn></v-toolbar-title>
					</v-toolbar> -->
					<v-card-text class="px-0 py-1">
						<v-container class="pa-0">
							<v-form class="elevation-1">
								<v-card-title class="pa-2 black white--text rounded rounded-b-0"
									>แก้ไขข้อมูล
									<v-spacer></v-spacer>
									<v-btn class="error" @click="dialog = true">ลบบัญชีผู้ใช้</v-btn>
								</v-card-title>
								<div class="pa-3">
									<v-row>
										<v-col>
											<v-text-field v-model="user.name" label="ชื่อ-นามสกุล"></v-text-field>
										</v-col>
									</v-row>
									<v-row>
										<v-col>
											<v-text-field v-model="user.department" label="แผนก/ฝ่าย"></v-text-field>
										</v-col>
										<v-col>
											<v-select v-model="user.role" :items="choices.roles" label="บทบาท"></v-select>
										</v-col>
									</v-row>
									<v-row>
										<v-col>
											<v-select v-model="user.status" :items="choices.status" label="สถานะ"></v-select>
										</v-col>
									</v-row>
									<v-row>
										<v-col class="text-right">
											<v-btn class="primary" @click="updateResource">ยืนยันการแก้ไข</v-btn>
										</v-col>
									</v-row>
								</div>
							</v-form>
							<v-form class="elevation-1 mt-2">
								<v-card-title class="pa-2 black white--text rounded rounded-b-0">แก้ไขรหัสผ่าน</v-card-title>
								<div class="pa-3">
									<v-row>
										<v-col>
											<v-text-field v-model="password" type="password" label="รหัสผ่านใหม่"></v-text-field>
										</v-col>
										<v-col>
											<v-text-field v-model="confirm_password" type="password" label="ยืนยันรหัสผ่านใหม่"></v-text-field>
										</v-col>
									</v-row>
									<v-row>
										<v-col class="text-right">
											<v-btn class="primary" @click="updatePassword">ยืนยันการแก้ไข</v-btn>
										</v-col>
									</v-row>
								</div>
							</v-form>
							
						</v-container>
					</v-card-text>
					<v-card-actions><v-btn block color="grey" class="white--text" @click="$router.push('/users')">ย้อนกลับ</v-btn></v-card-actions>
				</v-card>
			</v-col>
		</v-row>
		<v-snackbar v-model="toast.visible" :timeout="toast.timeout" :color="toast.color" bottom>
			{{ toast.message }}
			<v-btn color="white" text @click="toast.visible = false">ปิด</v-btn>
		</v-snackbar>
		<v-dialog v-model="dialog" persistent max-width="290">
			<v-card>
				<v-card-title class="error headline white--text">คุณต้องการลบใช่หรือไม่</v-card-title>

				<v-card-text class="pt-3">เมื่อดำเนินการแล้ว จะไม่สามารถกู้คืนได้อีก</v-card-text>

				<v-card-actions>
					<v-spacer></v-spacer>

					<v-btn color="primary" @click="removeUser(0)">ปฏิเสธ</v-btn>

					<v-btn color="error" @click="removeUser(1)">ยอมรับ</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</v-container>
</template>

<script>
	export default {
		mounted() {
			let app = this;
			this._id = this.$route.params.id;
			this.$http
				.get("https://api-kpsw-user-service.cloudary.dev/api/v1/user/" + this._id)
				.then((res) => {
					app.$auth.fetch();
					app.user = res.data;
					app.loading = false;
				})
				.catch((err) => {
					app.loading = false;
				});
		},
		data() {
			return {
				_id: null,
				dialog: false,
				user: {
					name: "",
					username: "",
					role: 0,
					status: 0,
					department: "",
				},
				loading: true,
				password: null,
				confirm_password: null,
				choices: {
					roles: [
						{
							text: "ผู้ดูแลระบบ",
							value: 99,
						},
						{
							text: "ผู้ใช้ทั่วไป",
							value: 0,
						},
					],
					status: [
						{
							text: "รอตรวจสอบ",
							value: 0,
						},
						{
							text: "ตรวจสอบแล้ว",
							value: 1,
						},
					],
				},
				toast: {
					visible: false,
					timeout: 2000,
					color: "error",
					message: "",
				},
			};
		},
		methods: {
			removeUser(ans) {
				let app = this;
				app.dialog = false;

				if (ans == 1) {
					this.$http
						.delete("https://api-kpsw-user-service.cloudary.dev/api/v1/user/" + app._id)
						.then((res) => {
							app.toast.color = "success";
							app.toast.message = "ลบสำเร็จ";
							app.toast.visible = true;
							setTimeout(() => {
								app.$router.push("/users");
							}, 1000);
						})
						.catch((err) => {
							app.toast.color = "error";
							app.toast.message = "ข้อมูลไม่ถูกต้อง";
							app.toast.visible = true;
						});
				}
			},
			updateResource() {
				let app = this;
				app.$http
					.put("https://api-kpsw-user-service.cloudary.dev/api/v1/user/" + app._id, app.user)
					.then((res) => {
						app.toast.color = "success";
						app.toast.message = "บันทึกสำเร็จ";
						app.toast.visible = true;
						console.log(res.data);
						setTimeout(() => {
							app.$router.push("/users");
						}, 1000);
					})
					.catch((err) => {
						app.toast.color = "error";
						app.toast.message = "ข้อมูลไม่ถูกต้อง";
						app.toast.visible = true;
						console.log(err.response);
					});
			},
			updatePassword() {
				let app = this;
				app.$http
					.put("https://api-kpsw-user-service.cloudary.dev/api/v1/user/" + app._id, {
						password: app.password,
					})
					.then((res) => {
						app.toast.color = "success";
						app.toast.message = "บันทึกสำเร็จ";
						app.toast.visible = true;
						console.log(res.data);
						setTimeout(() => {
							app.$router.push("/users");
						}, 1000);
					})
					.catch((err) => {
						app.toast.color = "error";
						app.toast.message = "ข้อมูลไม่ถูกต้อง";
						app.toast.visible = true;
						console.log(err.response);
					});
			},
		},
	};
</script>

<style></style>
