<template>
	<v-container>
		<vg-loading :loading="loading"></vg-loading>
		<v-row justify="center" v-if="!loading">
			<v-col lg="6">
				<v-card>
					<v-container>
						<v-row>
							<v-col class="py-0">
								<v-subheader>ชื่อ-นามสกุล : {{ student.firstname + " " + student.lastname }}</v-subheader>
							</v-col>
						</v-row>
						<v-row>
							<v-col class="py-0">
								<v-subheader>เลขประจำตัว : {{ student.student_number }}</v-subheader>
							</v-col>
						</v-row>
						<v-row>
							<v-col class="py-0">
								<v-subheader>กระทำผิดในคดี : {{ student_case.name }}</v-subheader>
							</v-col>
						</v-row>
						<v-row>
							<v-col class="py-3">
								<v-menu v-model="datepicker" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="250px">
									<template v-slot:activator="{ on }">
										<v-text-field outlined v-model="report.date" label="วันที่" readonly dense v-on="on" class="px-4" hide-details></v-text-field>
									</template>
									<v-date-picker v-model="report.date" locale="th" @input="datepicker = false"></v-date-picker>
								</v-menu>
							</v-col>
						</v-row>
						<v-row>
							<v-col class="py-0">
								<v-subheader>เวลา <vue-timepicker class="pl-2" v-model="report.time" format="HH:mm" close-on-complete></vue-timepicker></v-subheader>
							</v-col>
						</v-row>
						<v-row>
							<v-col class="">
								<v-textarea outlined="" class="px-4" label="รายละเอียดเพิ่มเติม" hide-details v-model="report.detail" dense></v-textarea>
							</v-col>
						</v-row>
						<v-row>
							<v-col class="">
								<v-file-input class="px-4" accept="image/*" v-model="evidenceImage" prepend-icon="mdi-camera" hide-details label="รูปภาพประกอบ" clearable outlined @click:clear="clearImage"></v-file-input>
								<v-img v-show="previewUrl" contain :src="previewUrl" class="ma-4 elevation-2 pa-0">
                  <v-btn icon color="red" absolute @click="clearImage"><v-icon>mdi-close</v-icon></v-btn>
                </v-img>
							</v-col>
						</v-row>
						<v-row>
							<v-col class="pb-5 text-left">
								<v-btn :to="'/student/' + $route.params.student_id + '/student-cases'">ย้อนกลับ</v-btn>
							</v-col>
							<v-col class="pb-5 text-right">
								<v-btn color="primary" @click="submitForm">ยืนยัน</v-btn>
							</v-col>
						</v-row>
					</v-container>
				</v-card>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
	export default {
		mounted() {
			let app = this;
			let prom = new Promise((resolve, reject) => {
				app.getStudent();
				resolve();
			});
			prom.then(() => {
				app.getStudentCase();
				app.report.student_id = app.$route.params.student_id;
				app.report.student_case_id = app.$route.query.caseId;
			});
		},
		computed: {
			previewUrl() {
				if (!this.evidenceImage) return;
				return URL.createObjectURL(this.evidenceImage);
			},
		},
		data() {
			return {
				student: {},
				student_case: {},
				loading: true,
				datepicker: null,
				evidenceImage: null,
				report: {
					date: dayjs().format("YYYY-MM-DD"),
					time: dayjs().format("HH:mm"),
					detail: "",
					image: "",
				},
			};
		},
		methods: {
      clearImage(e) {
        this.evidenceImage = null;
        this.report.image = "";
      },
			async addImage(files) {
				if (!files) return;
				// if (files.length === 0) return;
				const app = this;
				const form = new FormData();
				form.append("file", files);
				// app.$http.post("https://api-kpsw-storage-service.cloudary.dev/api/v1/storage/upload", form, {headers: {"Content-Type": "multipart/form-data"}})
				app.report.image = await app.$http.post("https://api-kpsw-storage-service.cloudary.dev/api/v1/storage/upload", form).then((res) => {
          return res.data.data;
				});
			},
			async submitForm() {
				let app = this;
				app.loading = true;
				if(app.evidenceImage) {
          await app.addImage(app.evidenceImage);
        }
				await app.$http
					.post("https://api-kpsw-report-service.cloudary.dev/api/v1/report", { ...app.report, datetime: `${app.report.date}T${app.report.time}:00.000Z` })
					.then((res) => {
						console.log(res.data);
						app.loading = false;
						app.$router.push("/");
					})
					.catch((err) => {
						console.log(err.response);
						app.loading = false;
					});
			},
			getStudent() {
				let app = this;
				app.loading = true;
				this.$http.get("https://api-kpsw-student-service.cloudary.dev/api/v1/student/" + this.$route.params.student_id).then((res) => {
					app.student = res.data;
					console.log(res.data);
					app.loading = false;
				});
			},
			getStudentCase() {
				let app = this;
				app.loading = true;
				this.$http.get("https://api-kpsw-case-service.cloudary.dev/api/v1/case/" + this.$route.query.caseId).then((res) => {
					app.student_case = res.data;
					console.log(res.data);
					app.loading = false;
				});
			},
		},
	};
</script>

<style></style>
