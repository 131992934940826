<template>
	<v-container>
		<vg-loading :loading="loading"></vg-loading>
		<v-row justify="center" dense>
			<v-col lg="12" sm="12">
				<v-toolbar class="elevation-2" dark>
					<v-toolbar-title>นักเรียนที่คะแนนเกินเกณฑ์</v-toolbar-title>
				</v-toolbar>
			</v-col>
		</v-row>
		<v-row justify="center" v-if="!loading" dense>
			<v-col lg="2" md="3" sm="3" xs="12" class="col-12">
				<v-select v-model="pagination.limit" outlined label="จำนวนรายการ" dense :items="limit" @change="changeLimit" hide-details></v-select>
			</v-col>
			<v-col lg="4" md="9" sm="9" xs="12" class="col-12">
				<v-text-field label="ค้นหา" class="pb-0 mb-0" prepend-inner-icon="mdi-magnify" v-model="search" outlined dense hide-details clearable @change="onChangeSearch"></v-text-field>
			</v-col>
			<v-col lg="2" md="4" sm="4" xs="4" class="col-4">
				<v-text-field v-model.number="limitScore" outlined label="เกณฑ์" type="number" dense hide-details @change="changeLimit"></v-text-field>
			</v-col>
			<v-col lg="2" md="4" sm="4" xs="4" class="col-4">
				<v-select v-model="grade" outlined label="ชั้น" dense :items="gradeList" hide-details @change="changeLimit"></v-select>
			</v-col>
			<v-col lg="2" md="4" sm="4" xs="4" class="col-4">
				<v-select v-model="room" outlined label="ห้อง" dense :items="roomList" hide-details @change="changeLimit"></v-select>
			</v-col>
			
		</v-row>
		<v-row dense class="px-2 mt-5 elevation-1">
			<v-col>
				<v-simple-table>
					<template>
						<thead>
							<tr>
								<th>#</th>
								<th>
									<DatatableSorting defineKey="NAME" :sortKey="pagination.sortKey" :sortValue="pagination.sortValue" @click.native="changeSort('NAME')">ชื่อ-นามสกุล</DatatableSorting>
								</th>
								<th>
									<DatatableSorting defineKey="GRADE_ROOM" :sortKey="pagination.sortKey" :sortValue="pagination.sortValue" @click.native="changeSort('GRADE_ROOM')">ชั้น</DatatableSorting>
								</th>
								<th>
									<DatatableSorting defineKey="NUMBER" :sortKey="pagination.sortKey" :sortValue="pagination.sortValue" @click.native="changeSort('NUMBER')">เลขที่</DatatableSorting>
								</th>
								<th>
									<DatatableSorting defineKey="SCORE" :sortKey="pagination.sortKey" :sortValue="pagination.sortValue" @click.native="changeSort('SCORE')">คะแนนที่หัก</DatatableSorting>
								</th>
							</tr>
						</thead>
						<tbody>
							<!-- <tr v-for="i in 10" :key="i">
								<td>{{ i }}</td>
								<td>Supavit Nounkhaow</td>
								<td>6/{{ i }}</td>
								<td>{{ i + 1 }}</td>
								<td>{{ i + 40 }}</td>
							</tr> -->
                            <tr v-for="(data, i) in reports" :key="i" >
								<td>{{ i+1 }}</td>
								<td>{{ data.fullname }}</td>
								<td>{{ data.grade_room }}</td>
								<td>{{ data.class_number }}</td>
								<td :class="getCaseColor(data.score_sum)">{{ data.score_sum * -1 }}</td>
							</tr>
						</tbody>
					</template>
				</v-simple-table>
			</v-col>
		</v-row>
		<v-row justify="center">
			<v-col lg="6" sm="12">
				<v-pagination v-model="pagination.current" :length="pagination.pages" @input="getReports"></v-pagination>
			</v-col>
		</v-row>
		<v-snackbar v-model="toast.visible" :timeout="toast.timeout" :color="toast.color" bottom>
			<v-col sm="auto"> {{ toast.message }}</v-col>
			<div @click="toast.visible = false" class="btn-close">x</div>
		</v-snackbar>
	</v-container>
</template>
<script>
	import DatatableSorting from "../components/Datatable/Sorting.vue";
	export default {
		components: {
			DatatableSorting
		},
		data() {
			return {
				toast: {
					visible: false,
					timeout: 2000,
					color: "error",
					message: "",
				},
				limitScore: 41,
				limit: [10, 20, 50, 100, 1000, 2000, 5000],
				pagination: {
					current: 1,
					pages: 1,
					limit: 10,
					total: 10,
                    sortKey: "SCORE",
                    sortValue: -1,
					maxClass: 10
				},
				gradeList: [
					{text: "ทั้งหมด", value: 0},
					{text: "1", value: 1},
					{text: "2", value: 2},
					{text: "3", value: 3},
					{text: "4", value: 4},
					{text: "5", value: 5},
					{text: "6", value: 6},
				],
				roomList: [
					{text: "ทั้งหมด", value: 0},
					{text: "1", value: 1},
					{text: "2", value: 2},
					{text: "3", value: 3},
					{text: "4", value: 4},
				],
				reports: [],
				loading: false,
				search: "",
				grade: "0",
				room: "0",
			};
		},
        mounted(){
			const roomList = [{text: "ทั้งหมด", value: 0}].concat(new Array(20).fill(0).map((v, i) => ({text: `${i+1}`, value: i+1})));
			this.roomList = roomList;
            this.getReports();
        }, 
		methods: {
			getReports() {
                const app = this;
                app.$http.get(`https://api-kpsw-topscore-service.cloudary.dev/api/v1/report/top-score?page=${app.pagination.current}&limit=${app.pagination.limit}&search=${app.search}&grade=${app.grade}&room=${app.room}&sortKey=${app.pagination.sortKey}&sortValue=${app.pagination.sortValue}&score=${app.limitScore}`)
                .then(res => {
                    app.reports = res.data.data;
                    app.pagination.current = res.data.meta.current_page;
                    app.pagination.total = res.data.meta.total;
                    app.pagination.pages = res.data.meta.pages;
					app.pagination.maxClass = res.data.meta.maxClass;
                    app.loading = false;
					const roomList = [{text: "ทั้งหมด", value: 0}].concat(new Array(app.pagination.maxClass).fill(0).map((v, i) => ({text: `${i+1}`, value: i+1})));
					app.roomList = roomList;
                })
            },
			onChangeSearch(val) {
                if (_.isNull(val)) {
				this.search=""
				}
				this.pagination.current = 1;
				this.getReports()
            },
			changeSort(sortKey) {
				const sortIterator = [0,1,-1];
				const index = sortIterator.indexOf(this.pagination.sortValue);
				this.pagination.sortValue = sortIterator[(index + 1) % 3];
				this.pagination.sortKey = sortKey;
				this.getReports();
			},
            getCaseColor(score) {
				return score > 80 ? "red--text" : score > 30 ? "orange--text" : "green--text";
			},
			changeLimit() {
				this.pagination.current = 1;
				this.getReports();
			}
		},
	};
</script>
