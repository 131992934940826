<template>
	<!-- <div class="mx-auto"> -->
	<v-container class="pt-15 col-sm-12 col-md-6" color="#edfeed">
		<vg-loading :loading="loading"></vg-loading>
		<v-card class="elevation-1" v-if="!loading">
			<v-img height="200px" src="/images/design/school-plate.jpg"></v-img>
			<v-toolbar class="primary white--text" flat dense>
				<v-toolbar-title class="subtitle-2">การกวดขันงานวินัยโรงเรียนกำแพงแสนวิทยา</v-toolbar-title>
				<v-spacer />
			</v-toolbar>
			<v-card-text @keyup.enter="login" class="px-3">
				<v-form ref="form" v-model="valid" lazy-validation>
					<v-text-field label="ชื่อบัญชี" name="login" v-model="username" :rules="rules.username" prepend-icon="mdi-account" type="text" required />

					<v-text-field id="password" label="รหัสผ่าน" v-model="password" :rules="rules.password" name="password" prepend-icon="mdi-form-textbox-lock" type="password" required />
				</v-form>
			</v-card-text>
			<v-card-actions>
				<v-btn color="secondary" @click="$router.push('/register')">สมัครสมาชิก</v-btn>
				<v-spacer />
				<v-btn color="primary" @click="login" :disabled="!valid">เข้าสู่ระบบ</v-btn>
			</v-card-actions>
		</v-card>
		<v-snackbar v-model="toast.visible" :timeout="toast.timeout" :color="toast.color" bottom>
			<v-col sm="auto"> {{ toast.message }}</v-col>
			<div  @click="toast.visible = false" class="btn-close">x</div>
		</v-snackbar>
		<!-- <vg-toast :visible="toast.visible" :timeout="toast.timeout" :color="toast.color" :message="toast.message"></vg-toast> -->
	</v-container>
	<!-- </div> -->
</template>

<script>
import { CODE } from "../assets/err-code";
	export default {
		mounted() {
			this.loading = false;
		},
		updated() {
			this.validate();
		},
		data() {
			return {
				username: null,
				password: null,
				loading: true,
				valid: false,
				rules: {
					username: [(v) => !!v || "โปรดระบุชื่อบัญชี", (v) => (v && v.length >= 6) || "ชื่อบัญชีต้องมีความยาวอย่างน้อย 6 ตัวอักษร"],
					password: [(v) => !!v || "โปรดระบุรหัสผ่าน", (v) => (v && v.length >= 6) || "รหัสผ่านต้องมีความยาวอย่างน้อย 6 ตัวอักษร"],
				},
				toast: {
					visible: false,
					timeout: 2000,
					color: "error",
					message: "",
				},
			};
		},
		methods: {
			validate() {
				if(this.$refs.form) this.$refs.form.validate();
			},
			login() {
				let app = this;
				app.loading = true;
				if (app.valid === true) {
					this.$auth.login({
						data: {
							username: app.username,
							password: app.password,
						},
						rememberMe: true,
						fetchUser: true,
					}).catch((err)=>{
						console.log("err",err.response.status);
						app.loading = false;
						app.password = "";
						app.valid = false;
						app.toast.message = _.get(CODE,err.response.status,'ล็อคอินไม่สำเร็จ');
						app.toast.visible = true;
						console.log("err",err);
					})
				}
			},
		},
	};
</script>

<style>
	.btn-close{
		width: 20px;
		height: 20px;
		text-align: center;
		position: absolute;
		top: 0;
		right: 0;
		cursor: pointer;
	}
	.btn-close:hover{
		background: #000;
	}
</style>
