<template> 
    <v-container>
      <vg-loading :loading="loading"></vg-loading>
      <v-row justify="center" v-if="!loading" dense>
        <v-col lg="6">
          <v-card tile>
            <v-toolbar dark class="elevation-0" dense>
              <v-toolbar-title>
                ล้างข้อมูลการกระทำความผิดแบบกลุ่ม
              </v-toolbar-title>
              <v-spacer></v-spacer>
            </v-toolbar>
            <v-card-text class="pa-0">
				<v-container class="pa-4">
					<v-autocomplete
						label="นักเรียนที่จะล้างข้อมูล"
						v-model="form.student_ids"
						placeholder="ชื่อ/นามสกุล/ชั้น/เลขประจำตัว"
						:items="students"
						:search-input.sync="search"
						item-text="search_text"
						item-value="_id"
						multiple
						outlined
						return-object
						:error="this.$v.form.student_ids.$error"
						:error-messages="this.$v.form.student_ids.$error ?'':''"
					>
						<template v-slot:selection="{}"></template>
						<template v-slot:item="data">
							<template>
							<v-list-item-content>
								<v-list-item-title>{{ data.item.fullname }}</v-list-item-title>
								<v-list-item-subtitle >
									{{ data.item.student_number }} ชั้น{{ data.item.grade_room }} เลขที่ {{ data.item.class_number }} คะแนนที่หัก <span :class="getCaseColor(data.item.score*-1)">{{ data.item.score }}</span>
								</v-list-item-subtitle>
							</v-list-item-content>
							</template>
						</template>
					</v-autocomplete>
<!-- {{ student_ids }} -->
					<v-row class="mb-1">
						<v-col>
						<v-simple-table dense>
							<template>
								<thead>
									<tr>
										<th>#</th>
										<th>เลขที่</th>
										<th>ชื่อ-นามสกุล</th>
										<th>ชั้น</th>
										<th>คะแนนที่หัก</th>
										<th></th>
									</tr>
								</thead>
								<tbody>
									<tr v-for="(data, i) in form.student_ids" :key="i" >
										<td>{{ data.student_number }}</td>
										<td>{{ data.class_number }}</td>
										<td>{{ data.fullname }}</td>
										<td>{{ data.grade_room }}</td>
										<td :class="getCaseColor(data.score*-1)">{{ data.score }}</td>
										<td @click="removeSection(i)">
											<v-icon small class="red--text" >mdi-close</v-icon>
										</td>
									</tr>
								</tbody>
								<tbody v-if="form.student_ids.length===0">
									<tr><td colspan="5" style="width:100vw;text-align: center;">โปรดเลือกนักเรียน</td></tr>
								</tbody>
							</template>
						</v-simple-table>
						</v-col>
					</v-row>
					<v-row class="px-5">
						<v-col class="pb-0 text-left">
						<v-btn @click="$router.go(-1)">ย้อนกลับ</v-btn>
						</v-col>
						<v-col class="pb-5 text-right">
						<v-btn color="primary" @click="confirmAdd()">บันทึก</v-btn>
						</v-col>
					</v-row>
				</v-container>
            </v-card-text>
            
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </template>
<script>
import { required, minValue } from "vuelidate/lib/validators";
import { isEmpty } from 'lodash';
export default {
	name:"AddActivity",
	data() {
		return {
			loading:false,
			students: [],
			searchTerm:"",
			pagination: {
				limit: 9999,
				current: 1,
				total: 0,
				pages: 1,
				next_page: 1,
				previous_page: 0,
			},
			form:{
				student_ids:[],
			},
			delaySearch:null
		}
	},
	validations: {
		form: {
			student_ids: { required },
		},
	},
	computed: {
		search: {
			get () {
				return this.searchTerm ? this.searchTerm : ""
			},
			
			set (searchInput) {
				if (this.searchTerm !== searchInput) {
					this.searchTerm = searchInput
					// clearTimeout(this.delaySearch);
					// this.delaySearch = setTimeout(()=>{
					// 	this.getStudents()
					// },1000)
					
				}
			}
		}
	},
	mounted() {
		this.getStudents()
	},
	methods: {
		getCaseColor(score) {
			return score > 80 ? "red--text" : score > 30 ? "orange--text" : "green--text";
		},
		getStudents() {
			let app = this;
			this.loading = true;
			app.$http
				.get(`https://api-activity.cloudary.dev/api/v1/activities/search-student?page=${this.pagination.current}&limit=${this.pagination.limit}&search=${this.search}`)
				.then((res) => {
					app.students = res.data;
					app.loading = false;
				})
				.catch((err) => {
					console.log(err);
					app.loading = false;
				});
		},
		removeSection(index){
			this.form.student_ids.splice(index, 1)
		},
		confirmAdd(){
				const app = this;
				this.$swal({
					title: "โปรดยืนยันการเลือกนักเรียนที่จะล้างข้อมูลการกระทำผิด",
					reverseButtons: true,
					showCancelButton: true,
				}).then((result) => {
					if (result.isConfirmed) {
						app.add()
					}
				})
			},
		add(){
			const app = this;
			this.$v.$touch();
			this.submitted = true;
			var isCheck = !this.$v.$error;
			if (isCheck) {
				this.loading = true;

				app.$http.post("https://api-kpsw-report-service.cloudary.dev/api/v1/report/clear", {
					"student_ids": app.form.student_ids.map((i)=>i._id)
				}).then((res) => {
					app.loading = false;
					app.form.student_ids = []
					app.$swal({
						title: "ล้างคดีสำเร็จ",
						icon: "success",
						toast: true,
						timer: 2000,
						showConfirmButton: false,
						timerProgressBar: true,
						position: "top-end",
					});
					app.$router.push("/");
				})
				.catch((err) => {
					console.log(err)
					app.loading = false;
				});
			}
		}
	},
}
</script>

<style>

</style>