<template>
	<v-container>
		<vg-loading :loading="loading"></vg-loading>
		<template v-if="!loading">
			<v-row justify="center">
				<v-col class="text-center pr-0" lg="2" sm="4">
					<v-card height="100px">
						<v-card-subtitle>วันนี้</v-card-subtitle>
						<v-card-text class="headline">{{ today_report }}</v-card-text>
					</v-card>
				</v-col>
				<v-col class="text-center px-1" lg="2" sm="4">
					<v-card height="100px">
						<v-card-subtitle>สับปดาห์นี้</v-card-subtitle>
						<v-card-text class="headline">{{ week_report }}</v-card-text>
					</v-card>
				</v-col>
				<v-col class="text-center pl-0" lg="2" sm="4">
					<v-card height="100px">
						<v-card-subtitle>เดือนนี้</v-card-subtitle>
						<v-card-text class="headline">{{ month_report }}</v-card-text>
					</v-card>
				</v-col>
			</v-row>
			<v-row justify="center">
				<v-col class="text-center" lg="3">
					<v-card height="150" :to="'/students'">
						<v-card-subtitle class="pb-0 pt-8">
							<v-icon class="display-3 primary--text">mdi-alert-box-outline</v-icon>
						</v-card-subtitle>
						<v-card-subtitle class="pt-8">รายงานคดี</v-card-subtitle>
					</v-card>
				</v-col>
				<v-col class="text-center" lg="3">
					<v-card height="150" to="/reports">
						<v-card-subtitle class="pb-0 pt-8">
							<v-icon class="display-3 primary--text">mdi-account-search-outline</v-icon>
						</v-card-subtitle>
						<v-card-subtitle class="pt-8">คดีทั้งหมด</v-card-subtitle>
					</v-card>
				</v-col>
			</v-row>
			<v-row justify="center">
				<v-col lg="6" class="grey--text">การรายงานประจำวันที่ {{ currentDate }}</v-col>
			</v-row>
			<v-row justify="center">
				<v-col lg="6">
					<v-simple-table class="elevation-3" >
						<template v-slot:default>
							<thead>
								<tr>
									<th class="text-left px-2">#</th>
									<th class="text-left px-2">เวลา</th>

									<th class="text-left px-2">ชื่อ-นามสกุล</th>
									<th class="text-left px-2">คดี</th>
									<th class="text-left px-2">ผู้รายงาน</th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="(item, index) in today_report_list" :key="index" @click="$router.push(`/reports/${item._id}`)" class="recent_item">
									<td class="px-2">{{ index + 1 }}</td>
									<td class="px-2">{{ changeFormatDate(item.datetime) }}</td>
									<td class="px-2">{{ item.student_name }}</td>
									<td class="px-2">
										{{ item.case_name }}
										<!-- <span>
											{{ item.case_name }}
										</span>
										<span class="grey--text" style="font-size: 12px;">
											โดย {{ item.user_name }}
										</span> -->
										<!-- <v-list-item-content class="pa-0">
											<v-list-item-title style="font-size: 14px;">{{ item.case_name }}</v-list-item-title>
											<v-list-item-subtitle class="grey--text" style="font-size: 12px;">โดย {{ item.user_name }}</v-list-item-subtitle>
										</v-list-item-content> -->
									</td>
									<td class="px-2">{{ item.user_name }}</td>
								</tr>
							</tbody>

						</template>
					</v-simple-table>
					<!-- <v-list three-line elevation="2">
						<template v-for="(item, index) in today_report_list">
							<v-list-item :key="item._id" :to="'/reports/' + item._id">
								<v-list-item-avatar>
									<v-icon large>mdi-account-circle</v-icon>
								</v-list-item-avatar>
								<v-list-item-content>
									<v-list-item-title>{{ item.student_name }}</v-list-item-title>
									<v-list-item-subtitle>{{ item.case_name }} เวลา {{ changeFormatDate(item.datetime) }}</v-list-item-subtitle>
									<v-list-item-subtitle>แจ้งโดย อ.{{ item.user_name }}</v-list-item-subtitle>
								</v-list-item-content>
							</v-list-item>
							<v-divider :key="index"></v-divider>
						</template>
					</v-list> -->
				</v-col>
			</v-row>
		</template>
	</v-container>
</template>

<script>
	
	export default {
		mounted() {
			this.getReports();
			this.currentDate = dayjs().locale("th").format("DD MMM BBBB");
		},
		data() {
			return {
				reports: [],
				loading: true,
				students: [],
				today_report_list: [],
				today_report: 0,
				week_report: 0,
				month_report: 0,
				currentDate: ""
			};
		},
		methods: {
			getReports() {
				let app = this;
				app.$http.get("https://api-kpsw-report-service.cloudary.dev/api/v1/report/stats").then((res) => {
					app.today_report_list = res.data.recent;
					app.today_report = res.data.daily;
					app.week_report = res.data.weekly;
					app.month_report = res.data.monthly;
					app.loading = false;
				});
			},
			changeFormatDate(date) {
				return dayjs(date).format("HH:mm")
			}
		},
		computed: {},
	};
</script>

<style>
	.recent_item {
		cursor: pointer
	}
</style>
