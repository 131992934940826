<template>
  <div class="d-flex">
    <div class="sort-active cursor-pointer">
      <span>
        <slot></slot>
        <v-icon small v-if="sortKey === defineKey && sortValue === 1">mdi-chevron-up</v-icon>
        <v-icon small v-else-if="sortKey === defineKey && sortValue === -1">mdi-chevron-down</v-icon>
        <v-icon small v-else>mdi-unfold-more-horizontal</v-icon>
      </span>
      
    </div>
    
  </div>
</template>

<script>
export default {
  props: {
    defineKey: String,
    sortKey: String,
    sortValue: Number,
  }
}
</script>

<style>
.cursor-pointer {
  cursor: pointer;
}
.sort-active :hover {
  text-underline-position: below;
  color: black;
}
</style>