import Vue from "vue";
import VueRouter from "vue-router";
import Login from "../views/Login.vue";
import changePassword from "../views/ChangePassword.vue";
import Register from "../views/Register.vue";
import EditProfile from "../views/EditProfile.vue";
import users from "./users";
import Profile from "../views/Profile.vue";

import Overview from "../views/Overview.vue";
import Student from "../views/Student.vue";
import Activity from "../views/Activity.vue";
import ActivityMGMT from "../views/Activity-mgmt.vue";
import AddActivity from "../views/AddActivity.vue";
import AddMultiCase from "../views/add-multi-case.vue";
import ResetMultiCase from "../views/reset-multi-case.vue";
import AddCase from "../views/add-case.vue";
import StudentCase from "../views/student-case";
import StudentCaseMulti from "../views/student-case-multi.vue";
import ReportStudent from "../views/report-student.vue";
import ReportStudentMulti from "../views/report-student-multi.vue";
import Report from "../views/Report.vue";
import InfoStudent from "../views/Info-student.vue";
import reportCase from "../views/report-case.vue";
import TopScore from "../views/TopScore.vue";

Vue.use(VueRouter);

const routes = [{
        path: "/login",
        name: "Login",
        component: Login,
        meta: {
            auth: false,
        },
    },
    {
        path: "/changepassword",
        name: "ChangePassword",
        component: changePassword,
        meta: {
            auth: true,
        },
    },
    {
        path: "/register",
        name: "Register",
        component: Register,
        meta: {
            auth: false,
        },
    },
    {
        path: "/edit/profile",
        name: "EditProfile",
        component: EditProfile,
        meta: {
            auth: true,
        },
    },
    {
        path: "/Profile",
        name: "Profile",
        component: Profile,
        meta: {
            auth: true,
        },
    },
    {
        path: "/",
        name: "overview",
        component: Overview,
        meta: {
            auth: true,
        },
    },
    {
        path: "/students",
        name: "students",
        component: Student,
        meta: {
            auth: true,
        },
    },
    {
        path: "/activity",
        name: "activity",
        component: Activity,
        meta: {
            auth: true,
        },
    },
    {
        path: "/activity-mgmt",
        name: "activity-mgmt",
        component: ActivityMGMT,
        meta: {
            auth: true,
        },
    },
    {
        path: "/add-activity/:id",
        name: "edit activity",
        component: AddActivity,
        meta: {
            auth: true,
        },
    },{
        path: "/add-multi-case",
        name: "add-multi-case",
        component: AddMultiCase,
        meta: {
            auth: true,
        },
    },{
        path: "/reset-multi-case",
        name: "reset-multi-case",
        component: ResetMultiCase,
        meta: { auth: true },
    },{
        path: "/add-activity",
        name: "add activity",
        component: AddActivity,
        meta: {
            auth: true,
        },
    },
    {
        path: "/add-case",
        name: "add case",
        component: AddCase,
        meta: {
            auth: [99],
        },
    },
    {
        path: "/student/:student_id/student-cases",
        name: "students-case",
        component: StudentCase,
        meta: {
            auth: true,
        },
    },
    {
        path: "/student-cases-multi",
        name: "students-case-multi",
        component: StudentCaseMulti,
        meta: {
            auth: true,
        },
    },
    {
        path: "/student/:student_id/student-cases",
        name: "students-case",
        component: StudentCase,
        meta: {
            auth: true,
        },
    },
    {
        path: '/reports/:id',
        name: '/reports/id',
        component: reportCase,
        meta: {
            auth: true
        }
    },
    {
        path: '/students/:student_id/reports/create',
        name: '/students/student_id/reports/create',
        component: ReportStudent,
        // props: (route) => ({ query: route.query.caseId }),
        meta: {
            auth: true
        }
    },{
        path: '/students/reports/create-multi',
        name: '/students/reports/create-multi',
        component: ReportStudentMulti,
        meta: {
            auth: true
        }
    },
    {
        path: '/students/:id',
        name: '/students/id',
        component: InfoStudent,
        meta: {
            auth: true
        }
    },
    {
        path: "/reports",
        name: "reports",
        component: Report,
        meta: {
            auth: true,
        },
    },
    {
        path: "/overscore",
        name: "overscore",
        component: TopScore,
        meta: {
            auth: true,
        },
    },
    ...users,
    {
        path: "/404",
        component: () =>
            import ("../views/404.vue"),
    },
    {
        path: "/403",
        component: () =>
            import ("../views/403.vue"),
    },

    {
        path: "*",
        redirect: '/404'
    },
];

const router = new VueRouter({
    mode: "history",
    // base: process.env.BASE_URL,
    routes,
});

export default router;