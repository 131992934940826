<template>
  <v-row justify="center" align="center">
    <v-dialog v-model="loading" hide-overlay persistent width="500">
    <v-card color="primary" dark>
      <v-card-text>
        รอสักครู่
        <v-progress-linear indeterminate color="white" height="12" class="mb-0"></v-progress-linear>
      </v-card-text>
    </v-card>
  </v-dialog>
  </v-row>
</template>
<script>
export default {
  props: ['loading']
}
</script>