<template>
	<v-bottom-navigation class="nav-bar-glow" color="indigo darken-4" background-color="blue lighten-4" fixed grow shift>
		<v-btn v-for="(item, index) in icons" :key="index" class="justify-center" icon :to="item.path">
			<span>{{ item.name }}</span>
			<v-icon size="24px">
				{{ item.icon }}
			</v-icon>
		</v-btn>
	</v-bottom-navigation>
</template>

<script>
export default {
	data: () => ({
		icons: [
			{ path: "/", icon: "mdi-store", name: "Store" },
			{ path: "/cart", icon: "mdi-cart", name: "Cart" },
			{ path: "/myinvoices", icon: "mdi-file", name: "Invoice" },
			// { path: "/dashboard", icon: "mdi-table-cog", name: "Dashboard" },
		],
	}),
};
</script>

<style>
.nav-bar-glow {
	box-shadow: rgba(50, 50, 93, 0.25) 0px -6px 12px -2px, rgba(0, 0, 0, 0.3) 0px -3px 7px -3px !important;
}
</style>