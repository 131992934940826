import Index from "../views/users/index.vue"
import Edit from "../views/users/edit.vue"
import Create from "../views/users/create.vue"
const router = [
    {
        path : '/users',
        name : 'users.index',
        component : Index,
        meta : {
            auth : [99]
        }
    },
    {
        path : '/users/edit/:id',
        name : 'users.edit',
        component : Edit,
        meta : {
            auth : [99]
        }
    },
    {
        path : '/users/create',
        name : 'users.create',
        component : Create,
        meta : {
            auth : [99]
        }
    }
];

export default router;