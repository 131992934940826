import Vue from 'vue';
export const state = {
    data: {
        student_ids: [],
        student_case_id: null,
    }
};

export const mutations = {
    setStudentIds(state, payload) {
        state.data.student_ids = payload;
    },
    setStudentCaseId(state, payload) {
        state.data.student_case_id = payload
    },
};

export const actions = {};

export const getters = {
    student_ids: state => state.data.student_ids,
    student_case_id: state => state.data.student_case_id,
};
