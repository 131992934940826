<template>
	<v-container fill-height class="my-5">
		<v-row class="d-flex flex-column px-3">
			<span class="primary--text font-weight-bold">เปลี่ยนรหัสผ่าน</span>
			<span class="grey--text text-caption">โปรดรักษารหัสผ่านให้เป็นความลับ</span>
			<v-divider class="mt-2"></v-divider>
		</v-row>
		<!-- <div class="form-changepassword"> -->
		<!-- <v-row>
			<v-col cols="12" md="4" class="mx-auto">
				<v-text-field :error="$v.password.$error" :error-messages="$v.password.$error ? errorMsgReqPassword : ''" v-model="password" label="รหัสผ่าน*" type="password" required></v-text-field>
			</v-col>
		</v-row> -->

		<v-row>
			<v-col cols="12" md="4" class="mx-auto">
				<v-text-field :error="$v.newPassword.$error" :error-messages="$v.newPassword.$error ? errorMsgReqNewPassword : ''" v-model="newPassword" label="รหัสผ่านใหม่*" type="password" required></v-text-field>
			</v-col>
		</v-row>

		<v-row>
			<v-col cols="12" md="4" class="mx-auto">
				<v-text-field :error="$v.confirmNewPassword.$error" :error-messages="$v.confirmNewPassword.$error ? errorMsgReqConfirmNewPassword : ''" v-model="confirmNewPassword" label="ยืนยันรหัสผ่านใหม่*" type="password" required></v-text-field>
			</v-col>
		</v-row>

		<v-btn @click="save" class="mt-5 mx-auto" color="primary">บันทึก</v-btn>
		<!-- </div> -->
	</v-container>
</template>

<script>
import { required, sameAs } from "vuelidate/lib/validators";
export default {
	data: () => ({
		// errorMsgReqPassword: "โปรดกรอกรหัสผ่าน",
		errorMsgReqNewPassword: "โปรดกรอกรหัสผ่านใหม่",
		errorMsgReqConfirmNewPassword: "โปรดกรอกยืนยันรหัสผ่านใหม่ให้ตรงกับรหัสผ่านใหม่",
		// password: null,
		newPassword: null,
		confirmNewPassword: null,
	}),
	validations: {
		// password: { required },
		newPassword: { required },
		confirmNewPassword: { required, sameAsPassword: sameAs("newPassword") },
	},
	methods: {
		save() {
			this.$v.$touch();
			var isCheck = !this.$v.$error;
			if (isCheck) {
				this.$swal({
					title: "โปรดยืนยันการเปลี่ยนรหัสผ่าน",
					reverseButtons: true,
					showCancelButton: true,
				}).then((result) => {
					if (result.isConfirmed) {
						axios
							.put("https://api-kpsw-auth-service.cloudary.dev/api/v1/auth/change_password", {
								password: this.newPassword,
							})
							.then((res) => {
								this.$swal({
									title: "เปลี่ยนรหัสผ่านสำเร็จ",
									icon: "success",
									toast: true,
									timer: 2000,
									showConfirmButton: false,
									timerProgressBar: true,
									position: "top-end",
								});
								this.newPassword = null;
								this.confirmNewPassword = null;
							})
							.catch((err) => {
								this.$swal({
									title: "เปลี่ยนรหัสผ่านไม่สำเร็จ",
									icon: "error",
									toast: true,
									timer: 2000,
									showConfirmButton: false,
									timerProgressBar: true,
									position: "top-end",
								});
							});
					}
				});
			}
		},
	},
};
</script>

<style>
.form-changepassword {
	width: 100%;
	max-width: 1000px;
}
</style>