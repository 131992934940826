<template>
	<v-container>
		<vg-loading :loading="loading"></vg-loading>
		<v-row justify="center" dense>
			<v-col lg="6" sm="12">
				<v-toolbar class="elevation-2" dark>
					<v-toolbar-title>รายการคดีทั้งหมด</v-toolbar-title>
					<v-spacer></v-spacer>
					<v-btn color="primary" @click.stop="exportDialog = true">พิมพ์รายงาน</v-btn>
				</v-toolbar>
			</v-col>
		</v-row>
		<v-row justify="center" v-if="!loading" dense>
			<v-col lg="2" md="3" sm="3" xs="12" class="col-12" >
				<v-select v-model="pagination.limit" outlined label="จำนวนรายการ" dense :items="limit" @change="changeLimit" hide-details></v-select>
			</v-col>
			<v-col lg="4" md="9" sm="9" xs="12" class="col-12">
				<v-text-field label="ชื่อคดี" class="pb-0 mb-0" prepend-inner-icon="mdi-magnify" v-model="search" outlined dense hide-details clearable @change="onChangeSearch"></v-text-field>
			</v-col>
		</v-row>
		<template v-for="(item, index) in reports">
			<v-row :key="index" justify="center" dense>
				<v-col lg="6" sm="12">
					<!-- <v-card :to="'/reports/'+item._id"> -->
					<v-card>
						<v-toolbar dark class="elevation-0">
							<v-toolbar-title>{{ item.student_number + " " + item.student_name }}</v-toolbar-title>
						</v-toolbar>
						<v-card-text>
							<v-card-subtitle class="py-0">คดี {{ item.case_name }}</v-card-subtitle>
							<v-card-subtitle class="py-0">วันที่ {{ changeFormatDate(item.datetime) }}</v-card-subtitle>
							<v-card-subtitle class="py-0">โดย อ.{{ item.user_name }}</v-card-subtitle>
						</v-card-text>
						<v-divider></v-divider>
						<v-card-actions>
							<!-- <v-btn color="primary">พิมพ์</v-btn> -->
							<v-spacer></v-spacer>
							<v-btn color="primary" :to="'/reports/' + item._id">ดู/แก้ไขข้อมูล</v-btn>
						</v-card-actions>
					</v-card>
				</v-col>
			</v-row>
		</template>
		<v-row justify="center">
			<v-col lg="6" sm="12">
				<v-pagination v-model="pagination.current" :length="pagination.pages" @input="getReports"></v-pagination>
			</v-col>
		</v-row>
		<v-dialog v-model="exportDialog" persistent max-width="500">
			<v-card>
				<v-toolbar dense class="elevation-1" dark>
					<v-toolbar-title>กรองรายงาน</v-toolbar-title>
				</v-toolbar>

				<v-card-text>
					<v-container>
						<v-row justify="center">
							<v-radio-group v-model="document_condition" row>
								<v-radio label="ทั้งหมด" value="0"></v-radio>
								<v-radio label="รายคน" value="1"></v-radio>
							</v-radio-group>
						</v-row>
						<v-row justify="space-around">
							<v-col>
								<div v-if="document_condition == '0'">
									<v-text-field label="ชั้น" v-model.number="grade" dense outlined placeholder="6"></v-text-field>
									<v-text-field label="ห้อง" v-model.number="room" dense outlined placeholder="3"></v-text-field>
								</div>
								<div v-if="document_condition == '1'">
									<v-text-field label="เลขประจำตัวนักเรียน" v-model.number="student_id" dense outlined placeholder="16523"></v-text-field>
								</div>
								<v-menu ref="startdate_menu" v-model="startdate_menu" :close-on-content-click="false" :return-value.sync="startdate" transition="scale-transition" offset-y min-width="290px">
									<template v-slot:activator="{ on, attrs }">
										<v-text-field v-model="startdate" label="วันที่เริ่มต้น" prepend-inner-icon="mdi-calendar-month" readonly outlined dense v-bind="attrs" v-on="on"></v-text-field>
									</template>
									<v-date-picker v-model="startdate" no-title scrollable local="th-TH">
										<v-spacer></v-spacer>
										<v-btn text color="primary" @click="startdate_menu = false">Cancel</v-btn>
										<v-btn text color="primary" @click="$refs.startdate_menu.save(startdate)">OK</v-btn>
									</v-date-picker>
								</v-menu>
								<v-menu ref="enddate_menu" v-model="enddate_menu" :close-on-content-click="false" :return-value.sync="enddate" transition="scale-transition" offset-y min-width="290px">
									<template v-slot:activator="{ on, attrs }">
										<v-text-field v-model="enddate" label="วันที่สิ้นสุด" prepend-inner-icon="mdi-calendar-month" readonly outlined dense v-bind="attrs" v-on="on"></v-text-field>
									</template>
									<v-date-picker v-model="enddate" no-title scrollable local="th-TH">
										<v-spacer></v-spacer>
										<v-btn text color="primary" @click="enddate_menu = false">Cancel</v-btn>
										<v-btn text color="primary" @click="$refs.enddate_menu.save(enddate)">OK</v-btn>
									</v-date-picker>
								</v-menu>
							</v-col>
						</v-row>
					</v-container>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn color="green darken-1" text @click="exportDialog = false">ยกเลิก</v-btn>
					<v-btn
						color="green darken-1"
						text
						v-if="document_condition == '0'"
						@click="
							exportDialog = false;
							getReportDocument();
						"
						>ยืนยัน</v-btn
					>
					<v-btn color="green darken-1" text v-if="document_condition == '1'" @click="getReportStudentDocument()">ยืนยัน</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-snackbar v-model="toast.visible" :timeout="toast.timeout" :color="toast.color" bottom>
			<v-col sm="auto"> {{ toast.message }}</v-col>
			<div  @click="toast.visible = false" class="btn-close">x</div>
		</v-snackbar>
	</v-container>
</template>

<script>
import dayjs from 'dayjs';

// const FileDownload = require('js-file-download');
// import FileDownload from "js-file-download";
	export default {
		mounted() {
			let app = this;
			app.getReports();
		},
		data() {
			return {
				search: "",
				reports: [],
				loading: true,
				grade: "",
				room: "",
				student_id: null,
				document_condition: "0",
				exportDialog: false,
				startdate_menu: false,
				enddate_menu: false,
				startdate: dayjs().format('YYYY-MM-DD'),
				enddate: dayjs().format('YYYY-MM-DD'),
				limit: [10,20,50,100,1000,2000,5000],
				toast: {
					visible: false,
					timeout: 2000,
					color: "error",
					message: "",
				},
				pagination: {
					current: 1,
					pages: 1,
					limit: 10,
					total: 10,
				}
			};
		},
		methods: {
			onChangeSearch(val){
				if (_.isNull(val)) {
				this.search=""
				}
				this.pagination.current = 1;
				this.getReports()
			},
			changeFormatDate(date) {
				return dayjs(date).locale("th").format("DD MMM BBBB HH:MM")
			},
			getReportStudentDocument() {
				let app = this;
				if (app.student_id == null || app.student_id == '') {
					app.toast.visible = true;
					app.toast.message = "กรุณาใส่เลขประจำตัวนักเรียน";
					return;
				}
				app.loading = true;
				app.exportDialog = false;

				const data = {
					start_date: app.startdate,
					end_date: app.enddate,
					filter_type: "student_number",
					student_number:app.student_id
				}

				//นร.
				let url = "/reports/export-student?start=" + app.startdate + "&end=" + app.enddate + "&student_id=" + app.student_id;

				axios
					.post("https://api-kpsw-report-service.cloudary.dev/api/v1/report/export", data, { responseType: 'blob' })
					.then((res) => {
							const aElement = document.createElement('a');
							aElement.setAttribute('download', `reports-${app.student_id}-${dayjs().format('YYYYMMDDHHmmss')}.xlsx`);
							const href = URL.createObjectURL(res.data);
							aElement.href = href;
							aElement.setAttribute('target', '_blank');
							aElement.click();
							URL.revokeObjectURL(href);
						app.loading = false;
						})
						.catch((err) => {
							app.loading = false;
							if (err.response.status===404) {
								app.toast.message = "ไม่พบข้อมูล";
								app.toast.visible = true;
							}
							console.log("err",err.response.status);
						});

				// app.$http
				// 	.get(url)
				// 	.then((resp) => {
				// 		app.loading = false;
				// 		console.log(resp.data);
				// 		window.open(resp.data.link);
				// 		// window.location.href = resp.data.link;
				// 		console.log(resp.data.link);
				// 	})
				// 	.catch((err) => {
				// 		app.loading = false;
				// 		console.log(err.response);
				// 	});
				
			},
			getReportDocument() {
				let app = this;
				app.loading = true;
				let url = "/reports/export?start=" + app.startdate + "&end=" + app.enddate;
				if (app.grade != "") url += "&class=" + app.grade;
				if (app.room != "") url += "&room=" + app.room;

				const data = {
					start_date: app.startdate,
					end_date: app.enddate,
					filter_type: "class_room",
				}
				if (app.grade!=='') {
					data['grade'] = app.grade
				}
				if (app.room!=='') {
					data['class'] = app.room
				}
				axios
					.post("https://api-kpsw-report-service.cloudary.dev/api/v1/report/export", data, { responseType: 'blob' })
					.then((res) => {
						const aElement = document.createElement('a');
						aElement.setAttribute('download', `reports-${dayjs().format('YYYYMMDDHHmmss')}.xlsx`);
						const href = URL.createObjectURL(res.data);
						aElement.href = href;
						aElement.setAttribute('target', '_blank');
						aElement.click();
						URL.revokeObjectURL(href);
						app.loading = false;
						})
						.catch((err) => {
							app.loading = false;
							console.log("err",err);
						});
			},
			getReports() {
				let app = this;
				app.$http
					.get(`https://api-kpsw-report-service.cloudary.dev/api/v1/report?limit=${this.pagination.limit}&page=${this.pagination.current}&search=${this.search}`)
					.then((res) => {
						app.reports = res.data.data;
						app.pagination.current = res.data.meta.current_page;
						app.pagination.total = res.data.meta.total;
						app.pagination.pages = res.data.meta.pages;
						app.loading = false;
					})
					.catch((err) => {
						app.loading = false;
					});
			},
			caseColors(times) {
				if (times == "0") return "green lighten-4";
				else if (times == "1" || times == "2") return "orange lighten-4";
				else if (times == "3") return "red lighten-4";
			},
			changeLimit() {
				this.pagination.current = 1;
				this.getReports();
			}
		},
	};
</script>

<style>
	.btn-close{
		width: 20px;
		height: 20px;
		text-align: center;
		position: absolute;
		top: 0;
		right: 0;
		cursor: pointer;
	}
	.btn-close:hover{
		background: #000;
	}</style>
