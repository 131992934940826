<template>
	<v-container>
		<vg-loading :loading="loading"></vg-loading>
		<v-row justify="center" dense>
			<v-col lg="12" sm="12">
				<v-toolbar class="elevation-2" dark>
					<v-toolbar-title>กิจกรรม</v-toolbar-title>
                    <v-spacer></v-spacer>
					<v-btn color="primary" to="/add-activity">เพิ่ม</v-btn>
				</v-toolbar>
			</v-col>
		</v-row>
		<v-row justify="center" v-if="!loading" dense>
			<v-col lg="3" md="3" sm="3" xs="12" class="col-12">
				<v-select v-model="pagination.limit" outlined label="จำนวนรายการ" dense :items="limit" @change="changeLimit" hide-details></v-select>
			</v-col>
			<v-col lg="9" md="9" sm="9" xs="12" class="col-12">
				<v-text-field label="ค้นหา" placeholder="ชื่อ/นามสกุล/ชั้น/กิจกรรม/เลขประจำตัว" class="pb-0 mb-0" prepend-inner-icon="mdi-magnify" v-model="search" outlined dense hide-details clearable @change="onChangeSearch"></v-text-field>
			</v-col>
			
		</v-row>
		<v-row dense class="px-2 mt-5 elevation-1">
			<v-col>
				<v-simple-table>
					<template>
						<thead>
							<tr>
								<!-- <th>#</th> -->
								<th>
									<DatatableSorting defineKey="name" :sortKey="pagination.sortKey" :sortValue="pagination.sortValue" @click.native="changeSort('name')">กิจกรรม</DatatableSorting>
								</th>
								<th>
									<DatatableSorting defineKey="hours" :sortKey="pagination.sortKey" :sortValue="pagination.sortValue" @click.native="changeSort('hours')">จำนวนชั่วโมง</DatatableSorting>
								</th>
								<th>
									<DatatableSorting defineKey="total_students" :sortKey="pagination.sortKey" :sortValue="pagination.sortValue" @click.native="changeSort('total_students')">ผู้เข้าร่วม</DatatableSorting>
								</th>
								<th></th>
							</tr>
						</thead>
						<tbody>
                            <tr class="" v-for="(data, i) in reports" :key="i"
							 
							>
								<!-- <td>{{ i+1 }}</td> -->
								<td>{{ data.name }}</td>
								<td>{{ data.hours }}</td>
								<td>{{ data.total_students }}</td>
								<td class="d-flex align-center">
									<v-icon small class="mr-2" style="height: fit-content;" @click="$router.push({ path: '/add-activity/'+data._id})">mdi-pencil</v-icon>
									<v-icon small class="red--text" style="height: fit-content;" @click="confirmDelete(data._id)">mdi-close</v-icon>
								</td>
								<!-- <td>{{ date(data.created_at) }}</td> -->
							</tr>
						</tbody>
					</template>
				</v-simple-table>
			</v-col>
		</v-row>
		<v-row justify="center">
			<v-col lg="6" sm="12">
				<v-pagination v-model="pagination.current" :length="pagination.pages" @input="getReports"></v-pagination>
			</v-col>
		</v-row>
		<v-snackbar v-model="toast.visible" :timeout="toast.timeout" :color="toast.color" bottom>
			<v-col sm="auto"> {{ toast.message }}</v-col>
			<div @click="toast.visible = false" class="btn-close">x</div>
		</v-snackbar>
	</v-container>
</template>
<script>
	import DatatableSorting from "../components/Datatable/Sorting.vue";
	export default {
		components: {
			DatatableSorting
		},
		data() {
			return {
				toast: {
					visible: false,
					timeout: 2000,
					color: "error",
					message: "",
				},
				limit: [10, 20, 50, 100, 1000, 2000, 5000],
				pagination: {
					current: 1,
					pages: 1,
					limit: 10,
					total: 10,
                    sortKey: "_id",
                    sortValue: -1,
					maxClass: 10
				},
				reports: [],
				loading: false,
				search: "",
				grade: "0",
				room: "0",
			};
		},
        mounted(){
            this.getReports();
        }, 
		methods: {
			getReports() {
                const app = this;
				app.loading = true;
                app.$http.get(`https://api-kpsw-activity-service.cloudary.dev/api/v1/activities/list?page=${app.pagination.current}&limit=${app.pagination.limit}&search=${app.search}&sortKey=${app.pagination.sortKey}&sortValue=${app.pagination.sortValue}`)
                .then(res => {
                    app.reports = res.data.data;
                    app.pagination.current = res.data.meta.current_page;
                    app.pagination.total = res.data.meta.total;
                    app.pagination.pages = res.data.meta.pages;
					app.pagination.maxClass = res.data.meta.maxClass;
                    app.loading = false;
                })
            },
			onChangeSearch(val) {
                if (_.isNull(val)) {
				this.search=""
				}
				this.pagination.current = 1;
				this.getReports()
            },
			changeSort(sortKey) {
				const sortIterator = [0,1,-1];
				const index = sortIterator.indexOf(this.pagination.sortValue);
				this.pagination.sortValue = sortIterator[(index + 1) % 3];
				this.pagination.sortKey = sortKey;
				this.getReports();
			},
            getCaseColor(score) {
				return score > 0 ? "green--text" : "";
			},
			changeLimit() {
				this.pagination.current = 1;
				this.getReports();
			},
			date(data){
				return dayjs(data).format("DD-MM-YYYY")
			},
			confirmDelete(activities_id){
				const app = this;
				this.$swal({
					title: "โปรดยืนยันลบกิจกรรมนี้",
					reverseButtons: true,
					showCancelButton: true,
				}).then((result) => {
					if (result.isConfirmed) {
						app.removeActivity(activities_id)
					}
				})
			},
			removeActivity(activities_id) {
				const app = this;
				this.loading = true;
				app.$http
					.delete(`https://api-activity.cloudary.dev/api/v1/activities/${activities_id}`)
					.then((res) => {
						app.loading = false;
						app.getReports()
					})
					.catch((err) => {
						console.log(err)
						app.loading = false;
					});
			}
		},
	};
</script>
